<template>
  AccountIndex
</template>

<script>
export default {
  name: "AccountIndex"
}
</script>

<style scoped>

</style>